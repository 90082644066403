import React from "react";
import { motion } from "framer-motion";
import { FaCode, FaBrain, FaDesktop } from 'react-icons/fa';

const ServiceCard = ({ icon, title, description, examples }) => {
  return (
    <motion.div 
      className="col-md-4 mb-4"
      whileHover={{ 
        scale: 1.05,
        transition: { duration: 0.3 }
      }}
    >
      <div className="card h-100 shadow-lg border-0">
        <div className="card-body text-center p-4">
          <div className="mb-4 text-primary">
            {icon}
          </div>
          <h4 className="card-title mb-3">{title}</h4>
          <p className="card-text text-muted mb-4">
            {description}
          </p>
          <div className="service-examples">
            <h6 className="text-secondary mb-3">Solutions Include:</h6>
            <ul className="list-unstyled">
              {examples.map((example, index) => (
                <li 
                  key={index} 
                  className="mb-2 text-muted"
                >
                  <span className="me-2">✓</span>{example}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

function Services() {
  const serviceData = [
    {
      icon: <FaCode size={60} className="mb-3" />,
      title: "Custom Software Development",
      description: "Transforming complex business challenges into elegant, scalable software solutions that drive operational efficiency and innovation.",
      examples: [
        "Enterprise Resource Planning (ERP)",
        "Advanced Inventory Management",
        "Custom Workflow Automation"
      ]
    },
    {
      icon: <FaBrain size={60} className="mb-3" />,
      title: "AI & Machine Learning",
      description: "Leveraging cutting-edge artificial intelligence to unlock predictive insights, automate intelligent processes, and create adaptive technologies.",
      examples: [
        "Predictive Analytics Platforms",
        "Intelligent Process Automation",
        "Advanced Recommendation Systems"
      ]
    },
    {
      icon: <FaDesktop size={60} className="mb-3" />,
      title: "Web Development",
      description: "Crafting responsive, high-performance web experiences that blend stunning design with robust functionality and seamless user interactions.",
      examples: [
        "Enterprise Web Applications",
        "Interactive Digital Platforms",
        "Scalable Cloud-Based Solutions"
      ]
    }
  ];

  return (
    <div className="container py-5">
      <div className="text-center mb-5">
        <motion.h2 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="display-5 mb-4"
        >
          Innovative Technology Solutions
        </motion.h2>
        <p className="lead text-muted mx-auto" style={{ maxWidth: '800px' }}>
          At Byte Sizes, we blend technical expertise with creative problem-solving to deliver transformative digital solutions that empower businesses to thrive in a rapidly evolving technological landscape.
        </p>
      </div>

      <div className="row g-4">
        {serviceData.map((service, index) => (
          <ServiceCard 
            key={index}
            icon={service.icon}
            title={service.title}
            description={service.description}
            examples={service.examples}
          />
        ))}
      </div>

      <motion.div 
        className="text-center mt-5"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
      >
        <h5 className="mb-3">Ready to Transform Your Business?</h5>
        <a href="/contact" className="btn btn-primary">
        <button 
          className="btn btn-primary btn-lg px-5 py-3 shadow-lg"
        >
          Schedule a Consultation
          
        </button>
        </a>
      </motion.div>
    </div>
  );
}

export default Services;