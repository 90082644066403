// src/components/TermsOfService.js

import React from "react";

function TermsOfService() {
 return (
   <div className="container mt-5">
     <h2>Terms of Service</h2>
     <p>
       Welcome to our services. By accessing or using our website and services, you agree to these Terms of Service.
     </p>

     <section className="mt-4">
       <h3>1. Services Overview</h3>
       <p>We provide professional services in:</p>
       <ul>
         <li>Web Development</li>
         <li>Software Development</li>
         <li>Artificial Intelligence and Machine Learning Solutions</li>
       </ul>
     </section>

     <section className="mt-4">
       <h3>2. User Obligations</h3>
       <p>When using our services, you agree to:</p>
       <ul>
         <li>Provide accurate and current information</li>
         <li>Maintain confidentiality of account credentials</li>
         <li>Use services only for lawful purposes</li>
         <li>Not interfere with service functionality</li>
       </ul>
     </section>

     <section className="mt-4">
       <h3>3. Intellectual Property</h3>
       <p>
         All content, designs, and solutions created by our team are our intellectual property. 
         Client-specific deliverables are subject to contractual agreements.
       </p>
     </section>

     <section className="mt-4">
       <h3>4. Service Limitations</h3>
       <p>We reserve the right to:</p>
       <ul>
         <li>Modify or discontinue services</li>
         <li>Refuse service to anyone</li>
         <li>Change pricing without prior notice</li>
         <li>Terminate accounts for violation of terms</li>
       </ul>
     </section>

     <section className="mt-4">
       <h3>5. Liability and Disclaimers</h3>
       <p>
         Our services are provided "as is" without warranties. 
         We are not liable for indirect, incidental, or consequential damages.
       </p>
     </section>

     <section className="mt-4">
       <h3>6. Payment and Billing</h3>
       <p>
         Payment terms, project scopes, and billing are detailed in individual 
         project contracts and service agreements.
       </p>
     </section>

     <section className="mt-4">
       <h3>7. Confidentiality</h3>
       <p>
         We maintain strict confidentiality of client information and 
         project details, as outlined in our separate Non-Disclosure Agreement.
       </p>
     </section>

     <section className="mt-4">
       <h3>8. Dispute Resolution</h3>
       <p>
         Any disputes will be resolved through arbitration in accordance with 
         the laws of our jurisdiction.
       </p>
     </section>

     <section className="mt-4">
       <h3>9. Modification of Terms</h3>
       <p>
         We may update these terms periodically. Continued use of our services 
         constitutes acceptance of the most recent terms.
       </p>
     </section>

     <section className="mt-4">
       <p>
         <small>Last Updated: {new Date().toLocaleDateString()}</small>
       </p>
       <p>
         By accessing our services, you acknowledge that you have read, understood, 
         and agree to these Terms of Service.
       </p>
     </section>
   </div>
 );
}

export default TermsOfService;
