import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FaCode, FaBrain, FaDesktop, FaRocket } from 'react-icons/fa';

const ServiceCard = ({ icon, title, description, link }) => {
  return (
    <motion.div 
      className="col-md-4 mb-4"
      whileHover={{ 
        scale: 1.05,
        transition: { duration: 0.3 }
      }}
    >
      <div className="card h-100 shadow-lg border-0 rounded-4">
        <div className="card-body text-center p-4">
          <div className="mb-4 text-primary">
            {icon}
          </div>
          <h5 className="card-title mb-3">{title}</h5>
          <p className="card-text text-muted mb-4">
            {description}
          </p>
          <Link to={link} className="btn btn-outline-primary">
            Learn More
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

const TemplateCard = ({ image, title, description, link }) => {
  return (
    <motion.div 
      className="col-md-4 mb-4"
      whileHover={{ 
        scale: 1.05,
        transition: { duration: 0.3 }
      }}
    >
      <div className="card h-100 shadow-lg border-0 rounded-4 overflow-hidden">
        <img 
          src={image} 
          className="card-img-top" 
          alt={title}
          style={{ 
            height: '250px', 
            objectFit: 'cover' 
          }}
        />
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text text-muted">{description}</p>
          <Link to={link} className="btn btn-outline-primary">
            Explore Template
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

function Home() {
  const services = [
    {
      icon: <FaCode size={60} />,
      title: "Custom Software Development",
      description: "Innovative solutions tailored to transform your business challenges into technological opportunities.",
      link: "/services"
    },
    {
      icon: <FaBrain size={60} />,
      title: "AI & Machine Learning",
      description: "Leverage cutting-edge AI to unlock insights, automate processes, and drive intelligent decision-making.",
      link: "/services"
    },
    {
      icon: <FaDesktop size={60} />,
      title: "Web Development",
      description: "Create stunning, responsive web experiences that captivate users and drive business growth.",
      link: "/services"
    }
  ];

  const templates = [
    {
      image: "https://cdn.shortpixel.ai/spai/w_1653+q_lossless+ret_img+to_webp/websitedemos.net/electronic-store-04/wp-content/uploads/sites/1055/2024/01/demo-screenshot.jpg",
      title: "E-Commerce Template",
      description: "A fully responsive, feature-rich template designed for modern online stores.",
      link: "/templates"
    },
    {
      image: "https://cdn.shortpixel.ai/spai/w_1653+q_lossless+ret_img+to_webp/websitedemos.net/tech-startup-02/wp-content/uploads/sites/708/2021/03/tech-social-img.jpg",
      title: "Business Dashboard",
      description: "Sleek, intuitive dashboard for comprehensive business analytics and insights.",
      link: "/templates"
    },
    {
      image: "https://cdn.shortpixel.ai/spai/w_1653+q_lossless+ret_img+to_webp/websitedemos.net/adham-hoffman-designer-bio-02/wp-content/uploads/sites/1178/2023/04/adham-hoffman-designer-bio-02.jpg",
      title: "Portfolio Showcase",
      description: "Elegant template to highlight your professional achievements and creative work.",
      link: "/templates"
    }
  ];

  return (
    <div className="bg-light">
      {/* Hero Section with Animated Gradient and Effects */}
      <motion.div 
        className="hero-section position-relative overflow-hidden text-white text-center py-5"
        style={{
          background: 'linear-gradient(-45deg, #3494E6, #2196F3, #1E88E5, #0D47A1)',
          backgroundSize: '400% 400%',
          animation: 'gradientBG 15s ease infinite'
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {/* Gradient Animation Keyframes */}
        <style>{`
          @keyframes gradientBG {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
          }
        `}</style>

        {/* Particle-like Background Effect */}
        <div 
          className="position-absolute top-0 start-0 w-100 h-100"
          style={{
            background: `
              radial-gradient(circle at 20% 80%, rgba(255,255,255,0.1) 0%, transparent 50%),
              radial-gradient(circle at 80% 20%, rgba(255,255,255,0.1) 0%, transparent 50%)
            `,
            opacity: 0.3,
            zIndex: 1
          }}
        />

        <div className="container position-relative" style={{ zIndex: 2 }}>
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <motion.h1 
                className="display-4 mb-4"
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                Transforming Ideas into Digital Realities
              </motion.h1>
              <motion.p 
                className="lead mb-4"
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
              >
                We craft cutting-edge software solutions, AI technologies, and web experiences that empower businesses to innovate and excel.
              </motion.p>
              <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: 0.6 }}
              >
                <Link 
                  to="/services" 
                  className="btn btn-light btn-lg px-5 py-3 shadow-lg"
                >
                  Discover Our Solutions
                </Link>
              </motion.div>
            </div>
          </div>
        </div>

        {/* Animated SVG Shapes in Background */}
        <svg 
          className="position-absolute bottom-0 start-0 w-100" 
          xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 1440 320"
        >
          <motion.path 
            fill="rgba(255,255,255,0.1)" 
            fillOpacity="1"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 2 }}
            d="M0,256L48,250.7C96,245,192,235,288,224C384,213,480,203,576,197.3C672,192,768,192,864,202.7C960,213,1056,235,1152,245.3C1248,256,1344,256,1392,256L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          />
        </svg>
      </motion.div>

      {/* Rest of the component remains the same */}
      {/* Services Section */}
      <div className="container py-5">
        <div className="text-center mb-5">
          <h2 className="display-6">Our Core Services</h2>
          <p className="text-muted">
            Innovative technologies designed to solve complex business challenges
          </p>
        </div>
        <div className="row">
          {services.map((service, index) => (
            <ServiceCard 
              key={index}
              icon={service.icon}
              title={service.title}
              description={service.description}
              link={service.link}
            />
          ))}
        </div>
      </div>

      {/* Templates Section */}
      <div className="container py-5 bg-white">
        <div className="text-center mb-5">
          <h2 className="display-6">Professional Templates</h2>
          <p className="text-muted">
            Pre-designed solutions to accelerate your digital presence
          </p>
        </div>
        <div className="row">
          {templates.map((template, index) => (
            <TemplateCard 
              key={index}
              image={template.image}
              title={template.title}
              description={template.description}
              link={template.link}
            />
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <motion.div 
        className="container-fluid text-white text-center py-5"
        style={{
          background: 'linear-gradient(-45deg, #3494E6, #2196F3, #1E88E5, #0D47A1)',
          backgroundSize: '400% 400%',
          animation: 'gradientBG 15s ease infinite',
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6 }}
      >
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <FaRocket size={60} className="mb-4 text-primary" />
            <h3 className="display-6 mb-4">
              Transform Your Vision into Reality
            </h3>
            <p className="lead mb-4">
              Let's collaborate and create something extraordinary. Your success is our passion.
            </p>
            <Link 
              to="/contact" 
              className="btn btn-primary btn-lg px-5 py-3 shadow-lg"
            >
              Start Your Project
            </Link>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Home;