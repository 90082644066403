import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FaEye, FaDesktop, FaShoppingCart, FaUser } from 'react-icons/fa';

const TemplateCard = ({ image, title, description, link, icon }) => {
  return (
    <motion.div 
      className="col-md-4 mb-4"
      whileHover={{ 
        scale: 1.05,
        transition: { duration: 0.3 }
      }}
    >
      <div className="card h-100 shadow-lg border-0 rounded-4 overflow-hidden">
        <div className="position-relative">
          <img 
            src={image} 
            className="card-img-top" 
            alt={title}
            style={{ 
              height: '250px', 
              objectFit: 'cover' 
            }}
          />
          <div 
            className="position-absolute top-0 end-0 m-3 bg-primary text-white rounded-circle d-flex align-items-center justify-content-center"
            style={{ 
              width: '50px', 
              height: '50px' 
            }}
          >
            {icon}
          </div>
        </div>
        <div className="card-body">
          <h5 className="card-title mb-3">{title}</h5>
          <p className="card-text text-muted mb-4">{description}</p>
          <Link to={link} className="btn btn-outline-primary">
            Preview Template
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

function Templates() {
  const templates = [
    {
      image: "https://via.placeholder.com/400x250",
      title: "E-Commerce Template",
      description: "A fully responsive, feature-rich template designed for modern online stores with advanced product showcasing capabilities.",
      link: "/templates/e-commerce",
      icon: <FaShoppingCart />
    },
    {
      image: "https://via.placeholder.com/400x250",
      title: "Business Dashboard",
      description: "Sleek and intuitive dashboard template for comprehensive business analytics, featuring interactive data visualization and reporting.",
      link: "/templates/business-dashboard",
      icon: <FaDesktop />
    },
    {
      image: "https://via.placeholder.com/400x250",
      title: "Portfolio Showcase",
      description: "Elegant and professional template to highlight creative achievements, perfect for designers, freelancers, and creative professionals.",
      link: "/templates/portfolio",
      icon: <FaUser />
    }
  ];

  return (
    <div className="bg-light">
      {/* Hero Section */}
      <motion.div 
        className="container-fluid bg-primary text-white text-center py-5"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <motion.h1 
              className="display-4 mb-4"
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              Professional Website Templates
            </motion.h1>
            <motion.p 
              className="lead mb-4"
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              Elevate your online presence with our meticulously crafted, responsive, and modern website templates.
            </motion.p>
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.6 }}
            >
              <Link 
                to="/contact" 
                className="btn btn-light btn-lg px-5 py-3 shadow-lg"
              >
                Custom Template Inquiry
              </Link>
            </motion.div>
          </div>
        </div>
      </motion.div>

      {/* Templates Section */}
      <div className="container py-5">
        <div className="text-center mb-5">
          <h2 className="display-6">Our Featured Templates</h2>
          <p className="text-muted">
            Professionally designed templates to jumpstart your digital presence
          </p>
        </div>
        <div className="row">
          {templates.map((template, index) => (
            <TemplateCard 
              key={index}
              image={template.image}
              title={template.title}
              description={template.description}
              link={template.link}
              icon={template.icon}
            />
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <motion.div 
        className="container-fluid bg-dark text-white text-center py-5"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6 }}
      >
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <FaEye size={60} className="mb-4 text-primary" />
            <h3 className="display-6 mb-4">
              Need a Custom Template?
            </h3>
            <p className="lead mb-4">
              We specialize in creating unique, tailored templates that perfectly match your brand and vision.
            </p>
            <Link 
              to="/contact" 
              className="btn btn-primary btn-lg px-5 py-3 shadow-lg"
            >
              Start Your Custom Design
            </Link>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Templates;