import React from "react";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos"; // Import AOS for animations

function About() {
  React.useEffect(() => {
    AOS.init(); // Initialize AOS animations
  }, []);

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <h2
            className="text-center mb-5"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            About Us
          </h2>
          <div
            className="card shadow-lg border-0"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div className="card-body p-4">
              <p className="lead text-center">
                At the cutting edge of technological innovation, we craft
                solutions that redefine industries and enable businesses to
                achieve their full potential. Our commitment to excellence and
                forward-thinking drives everything we do.
              </p>
              <hr className="my-5" />
              {/* Mission, Vision, Values */}
              <div className="row text-center mb-4">
                <div
                  className="col-md-4"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  <h5 className="fw-bold">Mission</h5>
                  <p>
                    To revolutionize industries by delivering intelligent,
                    scalable, and impactful solutions powered by technology.
                  </p>
                </div>
                <div
                  className="col-md-4"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h5 className="fw-bold">Vision</h5>
                  <p>
                    To lead the future of innovation by pushing boundaries and
                    creating solutions that inspire progress and enhance lives.
                  </p>
                </div>
                <div
                  className="col-md-4"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <h5 className="fw-bold">Values</h5>
                  <p>
                    Integrity, creativity, and a relentless pursuit of
                    excellence form the foundation of our company.
                  </p>
                </div>
              </div>
              <hr className="my-5" />
              {/* Expertise, Features, Impact */}
              <div className="row text-center">
                <div
                  className="col-md-4"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  <h5 className="fw-bold">Expertise</h5>
                  <p>
                    Our team of industry veterans excels in artificial
                    intelligence, machine learning, software engineering, and
                    cloud technologies.
                  </p>
                </div>
                <div
                  className="col-md-4"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  <h5 className="fw-bold">Core Features</h5>
                  <ul className="list-unstyled">
                    <li> Advanced AI Solutions</li>
                    <li> Scalable Cloud Services</li>
                    <li> Data-Driven Decision Making</li>
                    <li> Custom Software Development</li>
                  </ul>
                </div>
                <div
                  className="col-md-4"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <h5 className="fw-bold">Impact</h5>
                  <p>
                    Empowering businesses with innovative tools that increase
                    efficiency, enhance customer experiences, and drive
                    sustainable growth.
                  </p>
                </div>
              </div>
              <hr className="my-5" />
              {/* Call to Action */}
              <div
                className="d-flex flex-column align-items-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h5 className="fw-bold">Join Us on Our Journey</h5>
                <p className="text-center">
                  Be part of a movement that transforms technology into
                  meaningful impact. Together, we can shape the future of
                  innovation.
                </p>
                <button className="btn btn-primary btn-lg mt-3">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
