import React, { useState } from 'react';
import { FaCode, FaDesktop, FaRobot, FaCloud, FaLink } from 'react-icons/fa';

// Dummy project data (replace with actual projects)
const projectsData = [
  {
    id: 1,
    title: 'AI-Powered Customer Support Platform',
    category: 'Artificial Intelligence',
    description: 'Advanced AI chatbot solution with natural language processing.',
    technologies: ['Python', 'TensorFlow', 'React', 'Node.js'],
    imageUrl: '/images/ai-support-platform.jpg',
    clientName: 'TechCorp Solutions',
    projectDuration: '4 months',
    websiteLink: 'https://example.com'
  },
  {
    id: 2,
    title: 'Enterprise Cloud Migration System',
    category: 'Cloud Services',
    description: 'Comprehensive cloud migration and optimization platform.',
    technologies: ['AWS', 'Kubernetes', 'Docker', 'Terraform'],
    imageUrl: '/images/cloud-migration.jpg',
    clientName: 'Global Enterprises Inc.',
    projectDuration: '6 months',
    websiteLink: 'https://example.com'
  },
  {
    id: 3,
    title: 'E-Commerce Web Application',
    category: 'Web Development',
    description: 'Scalable e-commerce platform with microservices architecture.',
    technologies: ['React', 'Node.js', 'GraphQL', 'MongoDB'],
    imageUrl: '/images/ecommerce-platform.jpg',
    clientName: 'RetailNow',
    projectDuration: '3 months',
    websiteLink: 'https://example.com'
  },
  {
    id: 4,
    title: 'IoT Smart Home Management',
    category: 'Software Development',
    description: 'Integrated IoT solution for home automation and monitoring.',
    technologies: ['Python', 'React Native', 'MQTT', 'Firebase'],
    imageUrl: '/images/iot-home.jpg',
    clientName: 'SmartHome Technologies',
    projectDuration: '5 months',
    websiteLink: 'https://example.com'
  }
];

function PortfolioPage() {
  const [selectedCategory, setSelectedCategory] = useState('All');

  // Categories for filtering
  const categories = [
    'All', 
    'Artificial Intelligence', 
    'Web Development', 
    'Cloud Services', 
    'Software Development'
  ];

  // Filter projects
  const filteredProjects = selectedCategory === 'All' 
    ? projectsData 
    : projectsData.filter(project => project.category === selectedCategory);

  return (
    <div className="portfolio-page container py-5">
      {/* Page Header */}
      <div className="row mb-5">
        <div className="col-12 text-center">
          <h1 className="display-4 mb-3">Our Portfolio</h1>
          <p className="lead text-muted">
            Innovative Solutions Transforming Businesses
          </p>
        </div>
      </div>

      {/* Category Filter */}
      <div className="row mb-4 justify-content-center">
        <div className="col-md-8">
          <div className="btn-group w-100" role="group">
            {categories.map(category => (
              <button
                key={category}
                type="button"
                className={`btn ${selectedCategory === category ? 'btn-primary' : 'btn-outline-secondary'}`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Projects Grid */}
      <div className="row g-4">
        {filteredProjects.map(project => (
          <div key={project.id} className="col-md-6 col-lg-4">
            <div className="card h-100 shadow-sm">
              <div className="position-relative">
                <img 
                  src={project.imageUrl} 
                  className="card-img-top" 
                  alt={project.title} 
                />
                <div className="position-absolute top-0 end-0 p-2">
                  <span className="badge bg-primary">
                    {project.category}
                  </span>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title mb-3">{project.title}</h5>
                <p className="card-text text-muted mb-3">
                  {project.description}
                </p>

                {/* Technologies Used */}
                <div className="mb-3">
                  <strong>Technologies:</strong>
                  <div className="mt-2">
                    {project.technologies.map(tech => (
                      <span 
                        key={tech} 
                        className="badge bg-light text-dark me-2 mb-2"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>

                {/* Project Details */}
                <div className="row text-muted">
                  <div className="col-6">
                    <small>
                      <strong>Client:</strong> {project.clientName}
                    </small>
                  </div>
                  <div className="col-6">
                    <small>
                      <strong>Duration:</strong> {project.projectDuration}
                    </small>
                  </div>
                </div>
              </div>
              <div className="card-footer bg-transparent">
                <a 
                  href={project.websiteLink} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="btn btn-outline-primary w-100"
                >
                  <FaLink className="me-2" /> View Project
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* No Projects Found */}
      {filteredProjects.length === 0 && (
        <div className="row">
          <div className="col-12 text-center">
            <div className="alert alert-info">
              No projects found in this category.
            </div>
          </div>
        </div>
      )}

      {/* Our Capabilities Section */}
      <div className="row mt-5 bg-light p-4 rounded">
        <div className="col-12 text-center mb-4">
          <h3>Our Technical Capabilities</h3>
          <p className="text-muted">
            We excel in delivering cutting-edge technological solutions
          </p>
        </div>
        <div className="col-12">
          <div className="row text-center">
            <div className="col-md-3 col-sm-6 mb-3">
              <FaCode className="text-primary mb-3" size={50} />
              <h5>Custom Software Development</h5>
              <p className="text-muted">
                Tailored software solutions for unique business needs
              </p>
            </div>
            <div className="col-md-3 col-sm-6 mb-3">
              <FaDesktop className="text-primary mb-3" size={50} />
              <h5>Web Application Development</h5>
              <p className="text-muted">
                Responsive and scalable web platforms
              </p>
            </div>
            <div className="col-md-3 col-sm-6 mb-3">
              <FaRobot className="text-primary mb-3" size={50} />
              <h5>AI & Machine Learning</h5>
              <p className="text-muted">
                Intelligent solutions powered by advanced algorithms
              </p>
            </div>
            <div className="col-md-3 col-sm-6 mb-3">
              <FaCloud className="text-primary mb-3" size={50} />
              <h5>Cloud Services</h5>
              <p className="text-muted">
                Seamless cloud migration and management
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className="row mt-5 text-center">
        <div className="col-12">
          <h3>Let's Build Something Great Together</h3>
          <p className="text-muted mb-4">
            Interested in transforming your business with technology?
          </p>
          <a href="/contact" className="btn btn-primary btn-lg">
            Contact Our Team
          </a>
        </div>
      </div>
    </div>
  );
}

export default PortfolioPage;