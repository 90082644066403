// src/components/PrivacyPolicy.js

import React from "react";

function PrivacyPolicy() {
  return (
    <div className="container mt-5">
      <h2>Privacy Policy</h2>
      <p>
        Your privacy is important to us. This privacy policy explains what information we collect, how we use it, and how we protect it.
      </p>

      <section className="mt-4">
        <h3>Information We Collect</h3>
        <p>We collect various types of information to provide and improve our services:</p>
        <ul>
          <li>Personal Information: Contact details, company information, professional details</li>
          <li>Technical Information: IP address, browser type, device information</li>
        </ul>
      </section>

      <section className="mt-4">
        <h3>How We Use Your Information</h3>
        <p>We use collected information for the following purposes:</p>
        <ul>
          <li>Providing and improving our web development, software development, and AI/ML services</li>
          <li>Responding to customer inquiries</li>
          <li>Processing quotes and contracts</li>
          <li>Marketing and communication</li>
          <li>Technical support and service delivery</li>
        </ul>
      </section>

      <section className="mt-4">
        <h3>Data Protection</h3>
        <p>We implement robust security measures to protect your information, including:</p>
        <ul>
          <li>Data encryption</li>
          <li>Secure server infrastructure</li>
          <li>Regular security audits</li>
          <li>Strict access controls</li>
        </ul>
      </section>

      <section className="mt-4">
        <h3>Your Rights</h3>
        <p>You have the following rights regarding your personal information:</p>
        <ul>
          <li>Access your personal information</li>
          <li>Request data correction</li>
          <li>Opt-out of marketing communications</li>
          <li>Request data deletion</li>
        </ul>
      </section>

      <section className="mt-4">
        <h3>Contact Us</h3>
        <p>
          For any privacy-related questions or concerns, please contact us at:
          <br />
          Email: info@bytesizes.in
          <br />
          Phone: +91 9076902036
        </p>
      </section>

      <section className="mt-4">
        <p>
          <small>Last Updated: {new Date().toLocaleDateString()}</small>
        </p>
        <p>
          By using our services, you consent to the terms of this privacy policy.
        </p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
