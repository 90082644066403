// src/components/Footer.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';

function Footer() {
  const [email, setEmail] = useState('');

  const handleSubscribe = (e) => {
    e.preventDefault();
    // Add newsletter subscription logic here
    console.log('Subscribed with email:', email);
    setEmail('');
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="bg-secondary bg-opacity-10 text-dark py-5">
      <div className="container">
        <div className="row">
          {/* Company Info */}
          <div className="col-md-4 mb-4">
            <h5 className="mb-3 text-secondary">Byte Sizes</h5>
            <p className="text-muted">
              Innovative tech solutions that transform your digital landscape. 
              We're passionate about creating cutting-edge software that drives 
              business growth and user experience.
            </p>
          </div>

          {/* Quick Links */}
          <div className="col-md-2 mb-4">
            <h6 className="mb-3 text-secondary">Quick Links</h6>
            <ul className="list-unstyled">
              <Link to="/about" className="text-decoration-none text-secondary d-block mb-2">About Us</Link>
              <Link to="/services" className="text-decoration-none text-secondary d-block mb-2">Services</Link>
              <Link to="/portfolio-page" className="text-decoration-none text-secondary d-block mb-2">Portfolio</Link>
              <Link to="/blog-page" className="text-decoration-none text-secondary d-block mb-2">Blog</Link>
            </ul>
          </div>

          {/* Legal Links */}
          <div className="col-md-2 mb-4">
            <h6 className="mb-3 text-secondary">Legal</h6>
            <ul className="list-unstyled">
              <Link to="/privacy-policy" className="text-decoration-none text-secondary d-block mb-2">Privacy Policy</Link>
              <Link to="/terms-of-service" className="text-decoration-none text-secondary d-block mb-2">Terms of Service</Link>
              <Link to="/cookie-policy" className="text-decoration-none text-secondary d-block mb-2">
          Cookie Policy
        </Link>
            </ul>
          </div>

          {/* Newsletter Subscription */}
          <div className="col-md-4 mb-4">
            <h6 className="mb-3 text-secondary">Stay Updated</h6>
            <form onSubmit={handleSubscribe}>
              <div className="input-group">
                <input 
                  type="email" 
                  className="form-control" 
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className="btn btn-outline-secondary">Subscribe</button>
              </div>
            </form>

            {/* Social Media Icons */}
            <div className="social-icons mt-3">
              <a href="https://facebook.com/bytesizes" target="_blank" rel="noopener noreferrer" className="text-secondary me-3">
                <FaFacebook size={24} />
              </a>
              <a href="https://twitter.com/bytesizes" target="_blank" rel="noopener noreferrer" className="text-secondary me-3">
                <FaTwitter size={24} />
              </a>
              <a href="https://instagram.com/bytesizes" target="_blank" rel="noopener noreferrer" className="text-secondary me-3">
                <FaInstagram size={24} />
              </a>
              <a href="https://linkedin.com/company/bytesizes" target="_blank" rel="noopener noreferrer" className="text-secondary">
                <FaLinkedin size={24} />
              </a>
            </div>
          </div>
        </div>

        {/* Contact and Copyright */}
        <div className="row border-top pt-3 mt-3">
          <div className="col-md-6 text-muted">
            <p>
              <FaEnvelope className="me-2" /> info@bytesizes.in
              <FaPhoneAlt className="ms-3 me-2" /> +91 90769-02036
            </p>
          </div>
          <div className="col-md-6 text-end">
            <p className="text-secondary">&copy; 2024 Byte Sizes. All rights reserved.</p>
          </div>
        </div>
      </div>

      {/* Back to Top Button */}
      <button 
        onClick={scrollToTop} 
        className="btn btn-outline-secondary back-to-top position-fixed bottom-0 end-0 m-3"
      >
        Back to Top
      </button>
    </footer>
  );
}

export default Footer;