import React from 'react';

function CookiePolicy() {
  // Update page title when component mounts
  React.useEffect(() => {
    document.title = 'Cookie Policy - Byte Sizes';
  }, []);

  return (
    <div className="container my-5">
      <h1 className="mb-4">Cookie Policy</h1>
      <p className="lead">Last Updated: December 2024</p>

      <section className="mb-4">
        <h2 className="h3 mb-3">1. What Are Cookies?</h2>
        <p>
          Cookies are small text files stored on your device when you visit our website. 
          They help us improve your browsing experience, analyze site traffic, and provide 
          personalized content and advertisements.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="h3 mb-3">2. Types of Cookies We Use</h2>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Cookie Type</th>
              <th>Purpose</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Essential Cookies</td>
              <td>Enable core website functionality</td>
              <td>Session/Persistent</td>
            </tr>
            <tr>
              <td>Analytics Cookies</td>
              <td>Track website performance and user interactions</td>
              <td>30 days</td>
            </tr>
            <tr>
              <td>Functional Cookies</td>
              <td>Enhance user experience and personalization</td>
              <td>1 year</td>
            </tr>
            <tr>
              <td>Marketing Cookies</td>
              <td>Targeted advertising and marketing efforts</td>
              <td>1 year</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section className="mb-4">
        <h2 className="h3 mb-3">3. Specific Cookies We Use</h2>
        <ul>
          <li>Google Analytics for website traffic analysis</li>
          <li>Session management cookies</li>
          <li>User preference cookies</li>
          <li>Performance monitoring cookies</li>
        </ul>
      </section>

      <section className="mb-4">
        <h2 className="h3 mb-3">4. Cookie Management</h2>
        <p>
          You can control and manage cookies through your browser settings. 
          Most browsers allow you to:
        </p>
        <ul>
          <li>Block all cookies</li>
          <li>Allow only first-party cookies</li>
          <li>Delete existing cookies</li>
          <li>Use incognito/private browsing mode</li>
        </ul>
        <div className="alert alert-warning">
          <strong>Note:</strong> Blocking cookies may impact website functionality 
          and your user experience.
        </div>
      </section>

      <section className="mb-4">
        <h2 className="h3 mb-3">5. Third-Party Cookies</h2>
        <p>
          We may use third-party services like Google Analytics, marketing platforms, 
          and performance tracking tools that set their own cookies. These services 
          help us understand website usage and improve our digital offerings.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="h3 mb-3">6. Cookies in Our Services</h2>
        <p>
          As a software development company, we use cookies across various services:
        </p>
        <ul>
          <li>Web Applications</li>
          <li>Cloud Service Portals</li>
          <li>Client Dashboard</li>
          <li>AI-powered Platform</li>
        </ul>
      </section>

      <section className="mb-4">
        <h2 className="h3 mb-3">7. Updates to Cookie Policy</h2>
        <p>
          We may update this Cookie Policy periodically. We recommend reviewing 
          this page regularly for any changes. Continued use of our website 
          constitutes acceptance of the current policy.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="h3 mb-3">8. Contact Us</h2>
        <p>
          For any questions about our Cookie Policy, please contact us at:
        </p>
        <address>
          Email: privacy@bytesizes.in<br />
          Phone: +91 90769-02036<br />
          Address: 123 Tech Lane, Innovation Hub, Bangalore, India
        </address>
      </section>

      <div className="alert alert-info">
        <strong>Transparency Commitment:</strong> We are committed to protecting 
        your privacy and providing clear information about our data practices.
      </div>
    </div>
  );
}

export default CookiePolicy;