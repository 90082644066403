import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Dummy blog post data (replace with actual data or fetch from backend)
const blogPosts = [
  {
    id: 1,
    title: 'The Future of AI in Software Development',
    author: 'John Doe',
    date: 'December 10, 2024',
    category: 'Artificial Intelligence',
    excerpt: 'Exploring how AI is transforming software development processes and methodologies.',
    imageUrl: '/images/ai-development.jpg',
    readTime: '5 min read'
  },
  {
    id: 2,
    title: 'Web Development Trends for 2025',
    author: 'Jane Smith',
    date: 'November 25, 2024',
    category: 'Web Development',
    excerpt: 'Key technologies and approaches that will shape web development in the coming year.',
    imageUrl: '/images/web-trends.jpg',
    readTime: '7 min read'
  },
  {
    id: 3,
    title: 'Cloud Computing: Scaling Your Business',
    author: 'Mike Johnson',
    date: 'December 5, 2024',
    category: 'Cloud Services',
    excerpt: 'Strategies for leveraging cloud technologies to drive business growth.',
    imageUrl: '/images/cloud-computing.jpg',
    readTime: '6 min read'
  }
];

function BlogPage() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');

  // Categories
  const categories = [
    'All', 
    'Artificial Intelligence', 
    'Web Development', 
    'Cloud Services', 
    'Software Engineering', 
    'Technology Trends'
  ];

  // Filter blog posts
  const filteredPosts = blogPosts.filter(post => 
    (selectedCategory === 'All' || post.category === selectedCategory) &&
    post.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="blog-page container py-5">
      {/* Page Header */}
      <div className="row mb-5">
        <div className="col-12 text-center">
          <h1 className="display-4 mb-4">Byte Sizes Blog</h1>
          <p className="lead text-muted">
            Insights, Trends, and Innovations in Technology
          </p>
        </div>
      </div>

      {/* Search and Filter Section */}
      <div className="row mb-4">
        <div className="col-md-6 mb-3">
          <input 
            type="text" 
            className="form-control" 
            placeholder="Search blog posts..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="col-md-6 mb-3">
          <select 
            className="form-select"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            {categories.map(category => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Blog Posts Grid */}
      <div className="row">
        {filteredPosts.length === 0 ? (
          <div className="col-12 text-center">
            <p className="alert alert-info">
              No blog posts found matching your search or category.
            </p>
          </div>
        ) : (
          filteredPosts.map(post => (
            <div key={post.id} className="col-md-4 mb-4">
              <div className="card h-100 shadow-sm">
                <img 
                  src={post.imageUrl} 
                  className="card-img-top" 
                  alt={post.title} 
                />
                <div className="card-body">
                  <span className="badge bg-secondary mb-2">
                    {post.category}
                  </span>
                  <h5 className="card-title">{post.title}</h5>
                  <p className="card-text text-muted">
                    {post.excerpt}
                  </p>
                </div>
                <div className="card-footer bg-transparent d-flex justify-content-between">
                  <small className="text-muted">
                    {post.author} • {post.date}
                  </small>
                  <small className="text-muted">{post.readTime}</small>
                </div>
                <Link 
                  to={`/blog/${post.id}`} 
                  className="stretched-link"
                />
              </div>
            </div>
          ))
        )}
      </div>

      {/* Pagination */}
      <div className="row mt-4">
        <div className="col-12 d-flex justify-content-center">
          <nav aria-label="Blog posts navigation">
            <ul className="pagination">
              <li className="page-item disabled">
                <span className="page-link">Previous</span>
              </li>
              <li className="page-item active">
                <span className="page-link">1</span>
              </li>
              <li className="page-item">
                <a className="page-link" href="#!">2</a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#!">3</a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#!">Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {/* Newsletter Signup */}
      <div className="row mt-5 bg-light p-4 rounded">
        <div className="col-12 text-center">
          <h3>Stay Updated with Our Latest Insights</h3>
          <p className="text-muted mb-4">
            Subscribe to our newsletter for weekly tech updates and blog posts.
          </p>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="input-group">
                <input 
                  type="email" 
                  className="form-control" 
                  placeholder="Enter your email address"
                />
                <button className="btn btn-primary" type="button">
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogPage;